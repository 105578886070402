import React from 'react'

import { SearchAddress } from '@guiker/address-autocomplete-component'
import { Address } from '@guiker/base-entity'
import { useTranslation } from '@guiker/i18n'
import { Personal } from '@guiker/propsharing-shared'
import {
  OneThirdTwoThirdsColumnsGridLayout,
  TextField,
  TwoColumnsGridLayout,
  useFormContext,
  useGetInputProps,
  useWatch,
} from '@guiker/react-framework'
type SearchAddressComponentProps = {
  disabled?: boolean
  readOnly?: boolean
}

export const SearchAddressComponent: React.FC<SearchAddressComponentProps> = ({ disabled, readOnly }) => {
  const { t } = useTranslation()
  const { setValue, formState } = useFormContext()
  const { address } = useWatch()

  const onAddressChange = ({ address }: { address: Address }) => {
    setValue('address', { ...address } as Personal['address'], { shouldDirty: true, shouldValidate: true })
  }

  const addressInputProps = useGetInputProps({
    namespaces: ['common'],
    formPrefix: 'address',
    tPrefix: 'common:address',
    readOnly: true,
    defaultValue: { address },
  })

  return (
    <>
      {!readOnly && (
        <OneThirdTwoThirdsColumnsGridLayout>
          <TextField {...addressInputProps('unitNumber', { i18nKey: 'suiteNumber' })} readOnly={false} />
          <SearchAddress
            disabled={disabled}
            error={!!formState.errors['address']}
            errorMessage={!!formState.errors['address'] && t('errors:required')}
            readOnly={readOnly}
            label={t('main-propsharing:screens.createInvestorProfileScreen.step2.form.address')}
            onChange={(searched) => {
              const address = { ...searched.address, postalCode: searched.address.postalCode }
              return onAddressChange({ address })
            }}
            countryRestrictions={['CA']}
            required
          />
        </OneThirdTwoThirdsColumnsGridLayout>
      )}
      {address && (
        <TwoColumnsGridLayout>
          <TextField {...addressInputProps('streetNumber')} />
          <TextField {...addressInputProps('street')} label={t('common:address.streetName')} />
          <TextField {...addressInputProps('city')} readOnly={false} />
          <TextField {...addressInputProps('state')} />
          <TextField {...addressInputProps('postalCode')} />
        </TwoColumnsGridLayout>
      )}
    </>
  )
}
