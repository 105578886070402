import React, { useState } from 'react'

import { PSmall, useNavigate, useQuery, useTranslation } from '@guiker/react-framework'
import { camelCase, CountryCode, DateTime, getCurrencyByCountry, money } from '@guiker/shared-framework'
import {
  findRentCollectionColor,
  RentCollectionPieChart as BaseRentCollectionPieChart,
  RentCollectionStatusGrouping,
} from '@guiker/statistic-components'
import {
  RentCollectionKpiKey,
  RentCollectionKpiStatusGroups,
  RentCollectionStatusGroupName,
} from '@guiker/statistic-shared'

import { useAuthenticatedStatisticApiClient, useAuthenticationContext } from '../../hooks'

export const selectStatusGroup: { [key in RentCollectionKpiKey]: RentCollectionStatusGroupName } = {
  PENDING: 'pending',
  OVERDUE: 'bad',
  PROCESSING: 'processing',
  COLLECTED: 'collected',
  FAILED: 'bad',
  RECEIVED: 'received',
}

type Props = {
  date?: string
  region: CountryCode
  listingId?: string
  unitId?: string
}

export const RentCollectionPieChart: React.FC<Props> = (props) => {
  const { date = DateTime.local().toFormat('yyyy-MM'), region: country, listingId = null, unitId = null } = props
  const { user } = useAuthenticationContext()
  const { t } = useTranslation(['main-statistic'])
  const [error, setError] = useState(undefined)

  const statisticApi = useAuthenticatedStatisticApiClient()
  const navigate = useNavigate()

  const { data, isFetching } = useQuery(
    ['rentCollectionPieChart', user.id, date],
    async () => {
      const data = await statisticApi.getRentCollectionPieChart({
        queryParams: { month: date, country, listingId, unitId },
      })

      return data.map((point) => ({
        ...point,
        unit: money.getCurrencySymbol(getCurrencyByCountry(country), 'onlySymbol'),
        value: money.fromAmount(point.value, getCurrencyByCountry(country)).amount,
        label: t(`components.rentCollectionPieChart.status.${camelCase(point.key) as string}`),
        color: findRentCollectionColor(point.key),
        onHover: <RentCollectionStatusGrouping group={RentCollectionKpiStatusGroups[point.key]} groupKey={point.key} />,
        onClick: () => navigate(`../rent-collection?statusGroup=${selectStatusGroup[point.key]}`),
      }))
    },
    {
      enabled: !!user.metadata && !!country,
      onError: setError,
      retry: 1,
    },
  )

  return (
    <BaseRentCollectionPieChart
      header={<PSmall>{t('components.rentCollectionPieChart.header')}</PSmall>}
      data={data}
      error={error}
      isFetching={isFetching}
    />
  )
}
