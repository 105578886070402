import React, { useEffect } from 'react'

import { mainPathBuilder } from '@guiker/investment-shared'
import {
  InvestorProfileStatus,
  isRejected,
  SeriesStatus,
  UnavailableReason,
  webappRoutes,
} from '@guiker/propsharing-shared'
import {
  Button,
  ButtonWithLoader,
  Flex,
  InquiryCard,
  Link,
  PSmaller,
  RouterLink,
  useLayoutContext,
  useModal,
  useNavigate,
} from '@guiker/react-framework'
import { useFinancialInfoContext } from '@guiker/real-estate-components'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'
import { FormContent } from './FormContent'

type Props = {
  isLoading: boolean
  isSuccess: boolean
  displayName: string
}

export const PropSharingInquiryCard: React.FC<Props> = ({ isLoading, isSuccess }) => {
  const { tMain } = useT({ screenName: 'components.inquiryCard' })
  const navigate = useNavigate()
  const { openModal, closeModal, isOpen: isModalOpen } = useModal(false)

  const { isAtMostTablette, heightUpPx } = useLayoutContext()
  const isTallScreen = heightUpPx(800)
  const { currency } = useFinancialInfoContext()
  const {
    investorProfile,
    stockAllowance,
    inquiry,
    helpers: {
      stock: { latestSeries },
    },
    listing: { stock },
  } = useListingScreenContext()

  const isComingSoon = latestSeries.status === SeriesStatus.COMING_SOON
  const isOpen = latestSeries.status === SeriesStatus.OPEN
  const isPersonalAllowance = [UnavailableReason.PROFILE_ALLOWANCE, UnavailableReason.STOCK_TYPE_ALLOWANCE].includes(
    stockAllowance?.unavailableReason,
  )

  useEffect(() => {
    if (isSuccess && !!inquiry) {
      closeModal()
      if (
        !investorProfile ||
        (investorProfile && investorProfile.status !== InvestorProfileStatus.INFORMATION_COMPLETED)
      )
        navigate(webappRoutes.investorProfile.onboard)
      else navigate(webappRoutes.stockOrder.byId(inquiry.stockOrder.id).checkout.path)
    }
  }, [isSuccess, inquiry])

  return (
    <InquiryCard
      drawerBleeding={isTallScreen ? 264 : 188}
      buttonLabel={tMain(`${isAtMostTablette || isModalOpen ? 'cta' : 'preCta'}`)}
      open={isModalOpen}
      onOpen={openModal}
      onClose={closeModal}
      isLoading={isLoading}
      SubmitButton={({ fullWidth, ...props }) =>
        isComingSoon ? (
          <RouterLink to={mainPathBuilder.root.propSharing.path} fullWidth={fullWidth}>
            <Button fullWidth={fullWidth} {...props}>
              {tMain('comingSoonCTA')}
            </Button>
          </RouterLink>
        ) : isOpen && stockAllowance?.unavailableReason !== UnavailableReason.FULLY_FUNDED ? (
          <Flex flexDirection='column' gap={1}>
            <ButtonWithLoader
              fullWidth={fullWidth}
              type='submit'
              {...props}
              disabled={
                props.disabled || (!!investorProfile && isRejected(investorProfile)) || stockAllowance?.maxQuantity <= 0
              }
            />
            {stockAllowance?.maxQuantity <= 0 && isPersonalAllowance && (
              <PSmaller color={30} mb={0} textAlign='center'>
                {tMain('noAllowance.prefix')}
                <Link isExternalLink to='mailto:invest@guiker.com'>
                  <PSmaller color={60} mb={0}>
                    invest@guiker.com
                  </PSmaller>
                </Link>
                {tMain('noAllowance.suffix')}
              </PSmaller>
            )}
          </Flex>
        ) : (
          <></>
        )
      }
    >
      {stock && <FormContent currency={currency} isSuccess={isSuccess} stock={stock} isTallScreen={isTallScreen} />}
    </InquiryCard>
  )
}
