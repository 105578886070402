import React, { createContext, useMemo, useState } from 'react'

import { useAuthenticationContext } from '@guiker/authentication-context'
import {} from '@guiker/components-library'
import { money } from '@guiker/money'
import { PayInMethod } from '@guiker/payment-shared'
import {
  getListingHelpers,
  InvestorProfile,
  PropSharingInquiry,
  PropSharingListing,
  PropSharingListingWithInquiry,
  StockAllowance,
} from '@guiker/propsharing-shared'
import { FullScreenSpinner, generateUseContext } from '@guiker/react-framework'
import { useQuery } from '@guiker/react-query'
import { PropertyContextProvider } from '@guiker/real-estate-components'

import {
  useAuthApiClient,
  useAuthenticatedPropSharingListingIdContext,
  useInvestorProfileContext,
  usePayInMethodContext,
  usePublicApiClient,
  usePublicPropSharingListingIdContext,
} from '../hooks'

type ScreenContextType = {
  listing: PropSharingListingWithInquiry | PropSharingListing
  investorProfile: InvestorProfile
  refetchInvestorProfile: () => void
  payInMethod: PayInMethod
  inquiry: PropSharingInquiry
  setInquiry: (data: PropSharingInquiry) => void
  refetchListing: () => Promise<unknown>
  stockAllowance: StockAllowance
  refetchStockAllowance: () => Promise<unknown>
  helpers: ReturnType<typeof getListingHelpers>
}

export const ListingScreenContext = createContext<ScreenContextType>(null)

type WrappedListingScreenContextProviderProps = React.PropsWithChildren & {
  listing: PropSharingListingWithInquiry | PropSharingListing
  refetchStockAllowance?: () => Promise<unknown>
  refetchListing: () => Promise<unknown>
  stockAllowance?: StockAllowance
}

export const WrappedListingScreenContextProvider: React.FC<WrappedListingScreenContextProviderProps> = ({
  children,
  listing,
  stockAllowance,
  refetchListing,
  refetchStockAllowance,
}) => {
  const [inquiry, setInquiry] = useState<PropSharingInquiry>()
  const { investorProfile, refetchInvestorProfile } = useInvestorProfileContext()
  const { selected: payInMethod } = usePayInMethodContext()

  const helpers = useMemo(() => listing && getListingHelpers(listing, stockAllowance), [listing, stockAllowance])

  const value = {
    investorProfile,
    refetchInvestorProfile,
    inquiry,
    setInquiry,
    listing,
    refetchListing,
    payInMethod,
    stockAllowance,
    refetchStockAllowance,
    helpers,
  }

  return <ListingScreenContext.Provider value={value}>{children}</ListingScreenContext.Provider>
}

export const ListingScreenContextProvider: React.FC<React.PropsWithChildren> = (props) => {
  const { user } = useAuthenticationContext()

  if (user) {
    return <AuthListingScreenContextProviderWrapper {...props} />
  } else {
    return <PublicListingScreenContextProvider {...props} />
  }
}

const AuthListingScreenContextProviderWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const propSharingApiClient = useAuthApiClient()
  const { data: listing, refetch: refetchListing } = useAuthenticatedPropSharingListingIdContext({
    shouldThrowIfUndefined: false,
  })

  const { data: stockAllowance, refetch: refetchStockAllowance } = useQuery(
    'investor-profile-stock-allowance',
    (_) =>
      propSharingApiClient
        .getInvestorProfileAllowanceForStock({
          pathParams: {
            stockId: listing?.stock?.id,
          },
        })
        .then((res) => res.stockAllowance),
    {
      retry: 1,
      enabled: !!listing,
    },
  )

  if (!listing || !stockAllowance) {
    return <FullScreenSpinner />
  }

  return (
    <WrappedListingScreenContextProvider
      listing={listing}
      stockAllowance={stockAllowance}
      refetchListing={refetchListing}
      refetchStockAllowance={refetchStockAllowance}
    >
      <PropertyContextProvider property={listing.stock.property} currency={money.currency[listing?.stock?.currency]}>
        {children}
      </PropertyContextProvider>
    </WrappedListingScreenContextProvider>
  )
}

const PublicListingScreenContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { data: listing, refetch: refetchListing } = usePublicPropSharingListingIdContext({
    shouldThrowIfUndefined: false,
  })
  const propSharingApiClient = usePublicApiClient()

  const { data: stockAllowance, refetch: refetchStockAllowance } = useQuery(
    'investor-profile-stock-allowance',
    (_) =>
      propSharingApiClient.getAllowanceForStock({
        pathParams: {
          stockId: listing?.stock?.id,
        },
      }),
    {
      retry: 1,
      enabled: !!listing?.stock?.id,
    },
  )

  if (!listing) {
    return <FullScreenSpinner />
  }

  return (
    <WrappedListingScreenContextProvider
      listing={listing}
      refetchListing={refetchListing}
      refetchStockAllowance={refetchStockAllowance}
      stockAllowance={stockAllowance}
    >
      <PropertyContextProvider property={listing.stock.property} currency={money.currency[listing?.stock?.currency]}>
        {children}
      </PropertyContextProvider>
    </WrappedListingScreenContextProvider>
  )
}

export const useListingScreenContext = generateUseContext(ListingScreenContext)
