import { money } from '@guiker/money'
import { FrequencyType, SeriesType } from '@guiker/propsharing-shared'
import { useFinancialInfoContext } from '@guiker/real-estate-components'
import { math } from '@guiker/shared-framework'

import { useListingScreenContext } from '../../context'
import { useT } from '../../i18n'

export const buildKpiGrid = () => {
  const { tShared, tMain } = useT({
    entity: 'stock',
    screenName: 'screens.listingScreen.tab1.sections.financialDetails',
  })
  const { results, currency } = useFinancialInfoContext()
  const moneyFormatter = money.formatter(currency, 'onlySymbol', true)
  const {
    helpers: {
      stock: { latestSeries, totalEquities, pricePerUnit, propertyValue, latestFinancialStatements },
    },
  } = useListingScreenContext()

  return [
    latestFinancialStatements.frequencyType === FrequencyType.CLOSING
      ? {
          label: tShared('salePrice'),
          description: moneyFormatter(money.fromAmount(propertyValue, currency).monetized),
        }
      : latestSeries.seriesType === SeriesType.REFINANCING
      ? {
          label: tShared('series.deal.propertyValue'),
          description: moneyFormatter(money.fromAmount(propertyValue, currency).monetized),
        }
      : {
          label: tMain('purchasePrice'),
          description: moneyFormatter(money.fromAmount(propertyValue, currency).monetized),
        },
    {
      label: tMain('totalEquity'),
      tip: tMain('totalEquityTip'),
      description: moneyFormatter(money.fromAmount(totalEquities, currency).monetized),
    },
    {
      label: tMain('pricePerUnit'),
      description: `${money.fromAmount(pricePerUnit, currency).toString('onlySymbol')}`,
    },
    {
      label: tMain('irr'),
      tip: tMain('irrTip'),
      description: `${math.decimal.round(results.irr * 100, 2)}%`,
    },
  ]
}
