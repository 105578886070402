import React from 'react'

import { FormSection3 } from '@guiker/react-framework'
import { YearlyPAndL } from '@guiker/real-estate-components'

import { useTranslation } from '../../../i18n'

export const FinancialDetailsTab: React.FC = () => {
  const { t } = useTranslation(['main-propsharing'])
  const tPrefix = 'main-propsharing:screens.listingScreen'

  return (
    <>
      <FormSection3 title={t(`${tPrefix}.tab3.sections.yearlyPAndL`)}>
        <YearlyPAndL />
      </FormSection3>
    </>
  )
}
