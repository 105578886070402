import React from 'react'

import {
  LabeledMonetaryValue,
  LabeledPercentValue,
  LabeledValue,
  LabeledValueProps,
} from '@guiker/real-estate-components'
import { Currency } from '@guiker/shared-framework'

import { useT } from '../../i18n'

type Props = {
  i18nKey: string
  name?: string
  value: number
  currency?: Currency
  size?: 'large' | 'medium'
  type?: '%' | 'currency' | 'year'
  isInput?: boolean
  withoutDecimal?: boolean
  direction?: LabeledValueProps['direction']
}

export const ResultItem: React.FC<Props> = ({
  i18nKey,
  name,
  value,
  currency,
  type = '%',
  isInput,
  withoutDecimal = true,
  direction = 'row',
  size = 'medium',
}) => {
  const { tBase, tShared } = useT({})

  return (
    <>
      {isInput && <input type='hidden' name={name} value={value} />}
      {type === '%' ? (
        <LabeledPercentValue label={tShared(i18nKey)} value={value} direction={direction} size={size} />
      ) : type === 'year' ? (
        <LabeledValue
          label={tShared(i18nKey)}
          value={tBase('duration.interpolate.year_plural', { count: value })}
          size={size}
          direction={direction}
        />
      ) : (
        <LabeledMonetaryValue
          label={tShared(i18nKey)}
          currencySymbol='onlySymbol'
          value={{ value, currency }}
          noDecimal={withoutDecimal}
          direction={direction}
          size={size}
        />
      )}
    </>
  )
}
