import React from 'react'

import { Box, H3 } from '@guiker/components-library'
import { PropSharingListing } from '@guiker/propsharing-shared'
import { getDisplayName, ProjectWithDeveloper } from '@guiker/real-estate-shared'

type HeaderProp = {
  listing: PropSharingListing
  project: ProjectWithDeveloper
}

const Header: React.FC<HeaderProp> = ({ listing, project }) => {
  return (
    <Box maxWidth={'100%'}>
      <H3 mb={0}>{getDisplayName(listing, project)}</H3>
    </Box>
  )
}

export { Header }
