import React from 'react'

import { PageSection1 } from '@guiker/react-framework'
import {
  InvestmentPerformance,
  ListingDocuments,
  OfferingDetails,
  usePropertyContext,
} from '@guiker/real-estate-components'
import { hasDocument } from '@guiker/real-estate-shared'

import { CommonQuestions, PropertyDescription, RentalDetails } from '../../../components'

export const DetailsTab: React.FC = () => {
  const { property } = usePropertyContext()

  return (
    <PageSection1>
      <PropertyDescription />
      <InvestmentPerformance />
      <RentalDetails />
      <OfferingDetails />
      {hasDocument(property) && <ListingDocuments property={property} />}
      <CommonQuestions />
    </PageSection1>
  )
}
