import React from 'react'

import { UserFundingAccountWithdrawalsTable } from '@guiker/payment-app-components'
import { FundingAccountWithdrawal } from '@guiker/payment-shared'
import { PageSection2, PageSectionProps } from '@guiker/react-framework'

import { useT } from '../../i18n'

type FundingWithdrawalsTableProps = {
  withdrawals: FundingAccountWithdrawal[]
  cta: PageSectionProps['topActions']
}

const FundingWithdrawalsTable: React.FC<FundingWithdrawalsTableProps> = ({ withdrawals, cta }) => {
  const { tMain } = useT({ screenName: 'components.fundingAccountWithdrawalsTable' })

  return (
    <PageSection2 title={tMain('title')} topActions={cta}>
      <UserFundingAccountWithdrawalsTable
        withdrawals={withdrawals}
        emptyState={{
          description: tMain('placeholder.description'),
          label: tMain('placeholder.label'),
        }}
      />
    </PageSection2>
  )
}

export { FundingWithdrawalsTable }
