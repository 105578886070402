import React from 'react'

import { UserFundingAccountDepositsTable } from '@guiker/payment-app-components'
import { FundingAccountDeposit } from '@guiker/payment-shared'
import { PageSection2, PageSectionProps } from '@guiker/react-framework'

import { useT } from '../../i18n'

type FundingDepositsTableProps = {
  deposits: FundingAccountDeposit[]
  cta: PageSectionProps['topActions']
}

const FundingDepositsTable: React.FC<FundingDepositsTableProps> = ({ deposits, cta }) => {
  const { tMain } = useT({ screenName: 'components.fundingAccountDepositsTable' })

  return (
    <PageSection2 title={tMain('title')} topActions={cta}>
      <UserFundingAccountDepositsTable
        deposits={deposits}
        emptyState={{
          description: tMain('placeholder.description'),
          label: tMain('placeholder.label'),
        }}
      />
    </PageSection2>
  )
}

export { FundingDepositsTable }
