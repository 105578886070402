import React, { useEffect } from 'react'

import { useAuthenticationModalContext } from '@guiker/authentication-components'
import { AuthType, useAuthenticationContext } from '@guiker/authentication-context'
import { User } from '@guiker/authentication-shared'
import { mainPathBuilder } from '@guiker/investment-shared'
import { PayInMethodModals } from '@guiker/payment-app-components'
import { PayInMethodContextProvider } from '@guiker/payment-context'
import { SupportedCountries } from '@guiker/payment-shared'
import { useAuthApiClient } from '@guiker/propsharing-context'
import {
  allowedPaymentTypes,
  AuthApi,
  AuthApiClient,
  findLatestSeries,
  PropSharingInquiry,
  SeriesStatus,
  UnavailableReason,
} from '@guiker/propsharing-shared'
import {
  AnnouncementIcon,
  ApiForm,
  CheckBadgeIcon,
  Flex,
  FullScreenSpinner,
  H3,
  makeStyles,
  Note,
  NoteContextProvider,
  PageLayout,
  PageMetaTags,
  TabLayout,
  theme,
  useConfig,
  useDateFormatter,
  useMediaQuery,
  useTranslation,
  useWatch,
  yupResolver,
} from '@guiker/react-framework'
import {
  ProjectAndDocuments,
  PropertyPictures,
  useFinancialInfoContext,
  usePropertyContext,
} from '@guiker/real-estate-components'
import { getDisplayName, projectHasDocument } from '@guiker/real-estate-shared'

import { PropSharingInquiryCard, ReservationDisclosure } from '../../components'
import { isListingWithInquiry } from '../../components/utils'
import { ListingScreenContextProvider, useListingScreenContext } from '../../context'
import { usePayInMethodContext } from '../../hooks'
import { DetailsTab, FinancialDetailsTab } from './tabs'

const useStyles = makeStyles(
  (theme) => ({
    address: {
      ...theme.typography.variants.h3,
      fontWeight: 400,
      color: theme.palette.grey[60],
    },
    divider: {
      ...theme.typography.variants.h3,
      fontWeight: 300,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'PropSharingListingScreen' },
)

const Content: React.FC = () => {
  const classes = useStyles()
  const tPrefix = 'main-propsharing:screens.listingScreen'
  const { t } = useTranslation(['common-propsharing', 'main-propsharing'])
  const { listing, stockAllowance } = useListingScreenContext()
  const { property, project } = usePropertyContext()
  const { setInvestmentFinancials } = useFinancialInfoContext()

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'))
  const financials = useWatch({ name: 'financials', defaultValue: property.financialInfo.financials })
  const canPurchaseStock = !stockAllowance?.unavailableReason

  useEffect(() => {
    setInvestmentFinancials(financials)
  }, [financials])

  const address = getDisplayName(property, project)

  return (
    <Flex flexDirection='column' maxWidth='100%' pb={isExtraSmall && canPurchaseStock ? 21 : 0}>
      <Flex flexDirection='column' gap={6}>
        <PropertyPictures property={property} />
        <Flex fullWidth flexDirection='column' gap={2}>
          <H3 mb={0}>
            {listing.stock.tickerSymbol} <span className={classes.divider}>|</span>{' '}
            <span className={classes.address}>{address}</span>
          </H3>
          <Note statusColor='alabaster'>
            <ReservationDisclosure />
          </Note>
        </Flex>
        <TabLayout
          tabs={[
            {
              content: (
                <Flex flexDirection='column' gap={isMobile ? 10 : 16} pt={6}>
                  <DetailsTab />
                </Flex>
              ),
              label: t(`${tPrefix}.tab1.label`),
            },
            project || projectHasDocument(project)
              ? {
                  content: (
                    <Flex flexDirection='column' gap={isMobile ? 6 : 10} pt={6}>
                      <ProjectAndDocuments project={project} property={property} />
                    </Flex>
                  ),
                  label: t(`${tPrefix}.tab2.label.${project ? 'project' : 'documents'}`),
                }
              : undefined,
            {
              content: (
                <Flex flexDirection='column' gap={isMobile ? 6 : 10} pt={6}>
                  <FinancialDetailsTab />
                </Flex>
              ),
              label: t(`${tPrefix}.tab3.label`),
            },
          ].filter((t) => !!t)}
        />
      </Flex>
    </Flex>
  )
}

export const ListingScreenFormWrapped: React.FC = () => {
  const { t } = useTranslation(['common-propsharing', 'main-propsharing'])
  const { formatDate } = useDateFormatter()
  const { listing, refetchInvestorProfile, setInquiry, stockAllowance } = useListingScreenContext()
  const { property, project } = usePropertyContext()
  const {
    helpers: {
      stock: { latestSeries },
    },
  } = useListingScreenContext()
  const { openAuthenticationModal } = useAuthenticationModalContext()
  const { user: currentUser } = useAuthenticationContext()
  const { selected: selectedPayInMethod } = usePayInMethodContext()

  const config = useConfig()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const resolver = yupResolver(AuthApi.Schema.upsertPropSharingInquirySchema)
  const apiClient = useAuthApiClient()

  const onSubmit = (formData: Required<AuthApi.Schema.UpsertPropSharingInquirySchema>) => {
    const payload = { ...formData, payInMethod: selectedPayInMethod?.id }

    const signUpAndSubmit = new Promise<PropSharingInquiry>((resolve, reject) => {
      const onSuccess = async (user: User) => {
        const authApiClient = AuthApiClient({
          baseUrl: config.apiBaseUrl,
          logger: config.debug ? console : undefined,
          accessToken: user.accessToken,
        })

        await authApiClient.createInquiry({ payload: formData }).then(resolve).catch(reject)
      }

      return openAuthenticationModal(AuthType.LOGIN, { onSuccess, onDismiss: () => reject() })
    })

    return !currentUser
      ? signUpAndSubmit
      : isListingWithInquiry(listing) && inquiry?.id
      ? apiClient.updateInquiry({ pathParams: { id: inquiry.id }, payload })
      : apiClient.createInquiry({ payload: { ...payload, payInMethod: selectedPayInMethod?.id } })
  }

  if (!listing) {
    return <FullScreenSpinner />
  }

  const inquiry = isListingWithInquiry(listing) ? listing?.fractionalOwnershipInquiry : undefined
  const address = getDisplayName(listing, project)
  const leadPicture = listing.pictures[0]

  return (
    <PageLayout
      breadcrumbItems={[
        {
          to: mainPathBuilder.root.propSharing.path,
          label: t('main-investments:breadcrumb.investments'),
        },
        {
          label: `${listing.stock.tickerSymbol} - ${listing.stock.label}`,
        },
      ]}
    >
      <PageMetaTags
        subtitle={address}
        description={`${property.description} - ${address}`}
        imageUrl={leadPicture?.presignedDownloadUrl}
      />
      <Flex flexDirection='column' gap={4}>
        {latestSeries.status === SeriesStatus.COMING_SOON ? (
          <Note
            icon={<AnnouncementIcon size='medium' />}
            statusColor='alabaster'
            title={t('main-propsharing:components.inquiryCard.unavailableReason.COMING_SOON', {
              when: !latestSeries.startDate
                ? t('main-propsharing:components.inquiryCard.unavailableReason.SOON')
                : t('main-propsharing:components.inquiryCard.unavailableReason.COMING_SOON_DATE', {
                    date: formatDate(latestSeries.startDate),
                  }),
            })}
          />
        ) : stockAllowance?.unavailableReason ? (
          <Note
            icon={
              stockAllowance.unavailableReason === UnavailableReason.FULLY_FUNDED && <CheckBadgeIcon size='medium' />
            }
            statusColor={
              stockAllowance.unavailableReason === UnavailableReason.FULLY_FUNDED ? 'alabaster' : 'cancelled'
            }
            title={t(`main-propsharing:components.inquiryCard.unavailableReason.${stockAllowance.unavailableReason}`)}
          />
        ) : isListingWithInquiry(listing) ? (
          <Note statusColor='alabaster' title={t('main-propsharing:components.inquiryCard.existingInquiry')} />
        ) : undefined}
        <ApiForm
          formName='PropSharingInquiryForm'
          skipIfIsNotDirty={false}
          onSubmit={onSubmit}
          formOptions={{
            resolver,
            defaultValues: {
              listingId: listing?.id,
              stockId: listing?.stock?.id,
              askedPrice: listing?.stock ? findLatestSeries(listing?.stock)?.pricePerUnit : undefined,
              unitAmount: inquiry?.unitAmount ?? listing?.stock.minPerTransaction,
              financials: listing.stock.property.financialInfo.financials ?? {},
              results: listing.stock.property.financialInfo.results ?? {},
            },
          }}
          apiOptions={{
            onSuccess: async (inquiry) => {
              setInquiry(inquiry)
              refetchInvestorProfile()
              return inquiry
            },
          }}
        >
          {({ formState, isSuccess }) => (
            <Flex>
              <Content />
              {(!stockAllowance?.unavailableReason || !isSmallScreen) && (
                <PropSharingInquiryCard
                  isLoading={formState.isSubmitting}
                  isSuccess={isSuccess}
                  displayName={getDisplayName(property, project)}
                />
              )}
            </Flex>
          )}
        </ApiForm>
      </Flex>
    </PageLayout>
  )
}

export const ListingScreen: React.FC = () => {
  return (
    <ListingScreenContextProvider>
      <NoteContextProvider>
        <PayInMethodContextProvider allowedTypes={allowedPaymentTypes} region={SupportedCountries.Canada}>
          <ListingScreenFormWrapped />
          <PayInMethodModals />
        </PayInMethodContextProvider>
      </NoteContextProvider>
    </ListingScreenContextProvider>
  )
}
