import React from 'react'

import { IdentityVerification } from '@guiker/identity-verification-components'
import { useAuthenticatedPropSharingInvestorProfileContext } from '@guiker/propsharing-context'
import { ApiClientPayload, useMutation, useNavigate, useTranslation } from '@guiker/react-framework'

export const IdentityVerificationScreen: React.FC = () => {
  const { data: investorProfile, apiClient } = useAuthenticatedPropSharingInvestorProfileContext()
  const navigate = useNavigate()
  const { t } = useTranslation(['main-propsharing'])

  const tPrefix = 'main-propsharing:screens.createInvestorProfileScreen.step5'

  const navigateBackToOnBoarding = () => navigate('../../onboard?step=step5')

  const { mutate: onSubmit } = useMutation(
    async (payload: ApiClientPayload<typeof apiClient, 'updateInvestorProfileStepIdentityVerification'>) => {
      await apiClient.updateInvestorProfileStepIdentityVerification({ payload })
    },
    { onSuccess: navigateBackToOnBoarding },
  )

  return (
    <IdentityVerification
      defaultValue={investorProfile.identityVerification as any}
      title={t(`${tPrefix}.title`)}
      scope={{ type: 'InvestorProfile', id: investorProfile.id }}
      onSubmit={onSubmit as any}
      onClose={navigateBackToOnBoarding}
    />
  )
}
