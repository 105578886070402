import React from 'react'

import { PageSection2 } from '@guiker/react-framework'
import { RentalDetails as BaseRentalDetails, usePropertyContext } from '@guiker/real-estate-components'

import { useT } from '../../i18n'

export const RentalDetails: React.FC = () => {
  const { tMain } = useT({ screenName: 'screens.listingScreen.tab1.sections.rentalDetails' })
  const { property } = usePropertyContext()

  return (
    <PageSection2 title={tMain('header')}>
      <BaseRentalDetails property={property} />
    </PageSection2>
  )
}
